import { useRef } from "react"
import { motion, useScroll } from "framer-motion"

export default function App() {
    const pillarRef = useRef(null)
    const { scrollY } = useScroll()
    return (
        <motion.div
            style={{
                height: "300vh",
                backgroundColor: "#111",
                padding: "2px",
            }}
            ref={pillarRef}
        >
            <div
                style={{
                    position: "relative",
                    backgroundColor: "#ff000050",
                    height: "100vh",
                    width: "100%",
                    padding: `scrollY`,
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        backgroundColor: "blue",
                        height: `400px`,
                        width: "400px",
                        // overflow: "scroll",
                        transform: `translateX(${scrollY}px * 400)`,
                    }}
                ></div>
            </div>
        </motion.div>
    )
}
